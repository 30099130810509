import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import config from '../config/config';
import { useState } from 'react';
import { Square, StopCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function CircularProgressWithLabel(props) 
{
  return (
    <Box sx={{ 
      // position: 'relative', 
      display: 'inline-flex' 
    }}>
      <CircularProgress 
        variant="determinate" 
        size="5em"
        sx={{
        }}
        {...props} 
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography> */}
        <StopCircle sx={{ color: "white", fontSize:100 }} />

      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel(props) {
  const timeResolution = 100; // 100ms
  const [ timeLeft, setTimeLeft ] = useState(config.maxTimeRecord);
  // let timer;

  React.useEffect(() => {
    // setTimeout( ()=>{props?.stopFunc()}, config.maxTimeRecord);
    const timer = setInterval(() => {
      const resInSec = timeResolution/1000.;
      setTimeLeft((prevTime) => (prevTime-resInSec <0 ? 0 : prevTime - resInSec));
    }, timeResolution);
    return () => {
      clearInterval(timer);
      // props?.stopFunc();
    };
  }, []);

  return <CircularProgressWithLabel value={Math.floor(timeLeft/config.maxTimeRecord*100)} />;
}
import { CheckBox, Circle, Favorite, FavoriteBorder, Gavel } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Alert, Button, Checkbox, CircularProgress, FormGroup, FormHelperText, FormLabel, Icon, IconButton, Input, Snackbar, TextField } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import CircularWithValueLabel from './CircularProgressWithLabel';
import { useSpeechRecognition } from 'react-speech-kit';
import axios from 'axios';
import config from '../config/config';
import { NSAxios } from '../util/communication';
import ReactAudioPlayer from 'react-audio-player';
import { GAEvent } from '../App';
import { audioContext, audioSource, renewAudio, initAudio } from '../util/audio';

let _prevText = "";

export default function DocentMain(props)
{
  /**
   * 상태
   * 0 : 녹음 대기
   * 1 : 녹음 중
   * 2 : 다시녹음 / 보내기
   * 3 : AI 응답 대기
   * 4 : 도슨트의 발화
   */
  const [ mode, setMode ] = useState(0); // 0: idle, waiting for record
  const [ stt, setSTT] = useState('');
  const [ guideText, setGuideText ] = useState('');
  const [ flagOpenSnackbar, setFlagOpenSnackbar ] = useState(false);
  // let audioContext = new AudioContext();
  // let audioSource = audioContext.createBufferSource();
  // let audioContext;
  // let audioSource;
  let audioBuffer;
  let flagDidFirstRun = false;

  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) =>
    {
      // 음성인식 결과가 value 상태값으로 할당됩니다.
      setSTT(result);
      _prevText = result;
      console.log("녹음 완료 : ", result, _prevText);
    },
  });

  function recordClickHandler()
  {
    setMode(1);
    listen({lang:'ko-KR'}); // 녹음 시작
    GAEvent('Record');
    // setSTT('이혁진 작가에 대해 설명해줘.');
  }

  function stopClickHandler()
  {
    stop(); // 녹음 종료
    setMode(2);
  }

  function reloadClickHandler()
  {
    GAEvent('Re-record');
    doneClickHandler();
  }

  async function sendClickHandler()
  {
    console.log("비교 : ", stt, " : ", _prevText);
    if ( stt!==_prevText )
    {
      GAEvent('TextChanged');
      console.log("텍스트가 변경됨");
    }
    if ( !config.conversationID )
      await openNewConversation();

    const _url = `/aid/chat/ask/tts`;
    // const _url = `/aid/chat/ask`;
    const data = {
      "convoSessionId": config.conversationID,
      "question": stt,
      "length": "long",
      "resType": "tts"
    }

    stop();
    setMode(3);

    // const res = await NSAxios(_url, 'post', data, null, true);
    // console.log("Result : ", res);




    // window.speechSynthesis.onvoiceschanged = speak;
    // function speak()
    // {
    //   const retText = res.data.data.answer.content;
    //   console.log("answer : ", retText);
    //   const voices = window.speechSynthesis.getVoices();
    //   console.log("SS : ", window.speechSynthesis);
    //   console.log("Voices : ", voices);
    //   const lang = "ko-KR";
    //   const utterThis = new SpeechSynthesisUtterance(retText);

    //   utterThis.lang = lang;

    //   /* 한국어 vocie 찾기
    //     디바이스 별로 한국어는 ko-KR 또는 ko_KR로 voice가 정의되어 있다.
    //   */
    //   const kor_voice = voices.find(
    //     (elem) => elem.lang === lang || elem.lang === lang.replace("-", "_")
    //   );

    //   //힌국어 voice가 있다면 ? utterance에 목소리를 설정한다 : 리턴하여 목소리가 나오지 않도록 한다.
    //   if (kor_voice)
    //   {
    //     utterThis.voice = kor_voice;
    //   } else
    //   {
    //     return;
    //   }

    //   //utterance를 재생(speak)한다.
    //   window.speechSynthesis.speak(utterThis);

    // }


    // const audioChunks = [];
    // audioChunks.push(res.data);
    // const blob = new Blob(audioChunks, {type:'audio/mpeg-3'})
    // // config.audioContent = `data:audio/mp3;base64,${res.data}`;
    // config.audioContent = URL.createObjectURL(blob);

    

    // const mp3File = new File([res.data], 'aidocent');
    // const reader = new FileReader();
    // reader.onload = e=>{
    //   console.log("리더 이벤트 : ", e, e.target, e.target.result);
    //   // const file = 
    //   config.audioContent = e.target.result;
    //   setMode(4);
    // }
    // reader.readAsDataURL(mp3File);
    // const array = new Uint8Array(res.data);
    // // for (var i = 0; i < res.data.length; i++)
    // // {
    // //   array[i] = res.data.charCodeAt(i);
    // // }
    // const blob = new Blob([array.buffer], { type: 'audio/mpeg'});



    /**********************************************************************************
     * Fetch를 이용해 mp3를 받아와 response.blob()으로 blob을 받음
     */
    // const response = await fetch(config.apiServerURL + '/aid/chat/ask/tts', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json;charset=utf-8',
    //     'aidocent-rest-api-key': config.apiKey
    //   },
    //   body: JSON.stringify(data),
    // });
    // const myBlob = await response.blob();
    // console.log("Fetch URL : ", URL.createObjectURL(myBlob));
    // config.audioContent = URL.createObjectURL(myBlob);
    GAEvent('Send');


    // config.audioContent = URL.createObjectURL(blob);

    console.log("audioContext : ", audioContext);
    audioBuffer = await fetch(config.apiServerURL + '/aid/chat/ask/tts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'aidocent-rest-api-key': config.apiKey
      },
      body: JSON.stringify(data),
    })
    .then(res => res.arrayBuffer())
    .then(ArrayBuffer => audioContext.decodeAudioData(ArrayBuffer));

    setMode(4);
    GAEvent('Speak')
    
    audioSource.buffer = audioBuffer;
    audioSource.connect(audioContext.destination);
    audioSource.start();

    console.log("발화 시작 : ", audioContext.state);
    if ( audioContext.state==='suspended' )
    {
      setGuideText(`스피커 아이콘을 누르시면 설명을 시작합니다.${'\n'}
소리가 나지 않으면 무음 모드를 해제해 주세요.`);
    }

  }

  // function waitFinishClickHandler()
  // {
  //   setMode(4);
  // }

  function doneClickHandler()
  {
    renewAudio();
    // audioContext.close();
    setSTT('');
    setGuideText('');
    setMode(0);
    _prevText = "";
    setFlagOpenSnackbar(false);
    GAEvent('Reload');
  }

  function pauseResume()
  {
    console.log('Pause!', audioContext.state, audioSource, audioSource instanceof AudioBufferSourceNode,  audioSource.buffer );
    if ( audioContext.state==='suspended')
      audioContext.resume();
    else if ( audioContext.state==='running')
      audioContext.suspend();
  }

  function editQuestionHandler(e)
  {
    const _t = e.target.value;

    setSTT(_t);
  }

  async function goodAnswerHandler()
  {
    GAEvent('Like');
    setFlagOpenSnackbar(true);
  }

  /**
   * chatGPT와 대화가 오가는 방을 개설한다.
   */
  async function openNewConversation()
  {
    const _url = `/aid/chat/new/convo`;
    // const _header = {'aidocent-rest-api-key': config.apiKey};
    const res = await NSAxios(_url, 'post', null);
    config.conversationID = res.data.data.convoSessionId;
    console.log("new conversation res : ", res);
  }

  function boxByMode()
  {
    switch(mode)
    {
      case 0:   // 녹음 대기 상태
        return (
          <div className='vertical-center-block' onClick={recordClickHandler} >
            <IconButton aria-label="record" className="block">
              <Circle sx={{ color: red[600], fontSize:100 }} />
            </IconButton>
            <span className='icon-text block'>
              녹음하기
            </span>
          </div>
        );
        break;
      case 1:   // 녹음중
        return (
          <div className='vertical-center-block' onClick={stopClickHandler} >
            <IconButton aria-label="record" className="block">
              <CircularWithValueLabel stopFunc={stopClickHandler} />
            </IconButton>
            <span className='icon-text block'>
              녹음종료
            </span>
          </div>
        );
        break;
      case 2:   // 다시 녹음 / 보내기
        return (
          <>
          <div className='vertical-center-block' onClick={reloadClickHandler}>
            <IconButton aria-label="record" className="block">
              <RefreshIcon sx={{ color: "white", fontSize:100 }} />
            </IconButton>
            <span className='icon-text block'>
              다시 녹음하기
            </span>
          </div>
          <div className='vertical-center-block' onClick={sendClickHandler}>
            <IconButton aria-label="record" className="block">
              <SendIcon sx={{ color: blue[600], fontSize:100 }} />
            </IconButton>
            <span className='icon-text block'>
              AI에게 보내기
            </span>
          </div>
          </>
        );
        break;
    
      case 3: // AI 응답 대기
        return (
          <>
            <div className='vertical-center-block' >
              <CircularProgress 
                // variant="determinate" 
                sx = {{ width: 200}}
                // style={{width:"150px", color:"white"}}
                style={{marginTop:"50px"}}
              />
            </div>
          </>
        );
        break;
    
      case 4: // AI 발화
        return (
          <>
            <div className='vertical-center-block'>
              <div onClick={pauseResume} >
                <VolumeUpIcon sx={{ color: blue[600], fontSize:100 }} />
                <span className='icon-text block'>
                  Docent :
                </span>

              </div>
              <div style={{display:'inline'}} className='stop-listening'>
                <Button 
                  style={{display:'inline-flex'}}
                  startIcon={<Favorite />}
                  variant='contained'
                  className=''
                  onClick={goodAnswerHandler}
                >
                  답변이 좋았어요
                </Button>
                <Button 
                  onClick={doneClickHandler} 
                  variant='outlined'
                  style={{marginLeft: '10px'}}
                  // className='stop-listening'
                >
                  다른 질문 하기
                </Button>
              </div>
              {/* <ReactAudioPlayer */}
              {/* <audio
                src={config.audioContent}
                autoPlay
                controls
              /> */}
                <Snackbar
                  open={flagOpenSnackbar}
                  autoHideDuration={3000}
                  onClose={doneClickHandler}
                  anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
                  // message="좋아요 감사합니다! 다른 질문을 또 입력해 주세요."
                  // action={action}
                >
                  <Alert severity="info">
                    좋아요 감사합니다! 다른 질문을 또 입력해 주세요.
                  </Alert>
                </Snackbar>

            </div>
          </>
        );
        break;

      default:
        break;
    
    
    }

  }

  useEffect( ()=>{
    if ( flagDidFirstRun )
      return;
    flagDidFirstRun = true;

    initAudio();
    // audioContext = new AudioContext();
    // audioSource = audioContext.createBufferSource();
    console.log("First Run.", audioContext, audioSource);
  }, []);

  return (
    <>
      <div className='guide-text'>{guideText}</div>
      <div className="center-icon-wrapper" >
        {/* <div className='vertical-center-block'>
          <IconButton aria-label="record" className="block">
            <Circle sx={{ color: red[600], fontSize:100 }} />
          </IconButton>
          <span className='icon-text block'>
            녹음하기
          </span>
        </div> */}
        { boxByMode() }
      </div>
      {
        mode===0
        ?
        <div className='talk-to-ai'>
          버튼을 눌러 인공지능과 대화하세요.<br></br>
          "(작품명 혹은 작가, 전시회) 설명해줘" </div>
        : null
      }
      <div className='recorded-text'>
        {mode===2?
        <>
        <FormGroup style={{display:"inline-flex", width:"80%"}}>
          {/* <FormLabel className="recorded-text">질문을 적절히 수정하세요.</FormLabel> */}
          <Input 
            variant="standard" 
            // label="질문을 적절히 수정하세요"
            defaultValue={stt}
            value={stt}
            helperText="Some important text"
            color="warning"
            focused
            sx={{  }}
            style={{ color: 'white', textAlign:"center !important"}}
            fullWidth
            multiline
            onChange={editQuestionHandler}
          />
          <FormHelperText className="recorded-text recorded-text-helper">
            질문을 적절히 수정한 후 보내기를 눌러 주세요.
          </FormHelperText>

        </FormGroup>
        </>
        :stt}
      </div>
      {/* <audio id="docentAudio"
        src={config.audioContent}
        autoPlay
        controls
      /> */}

    </>
  )
}
import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import DocentMain from './components/DocentMain';
import ReactGA from 'react-ga4';
import config from './config/config';


export function GAEvent(action)
{
  ReactGA.event({
    category: "user-action",
    action: action,
    // label: "your label", // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
}

if ( config.env=='production')
{
  console.log = ()=>{};
  console.info = ()=>{};
  console.error = ()=>{};
}

function App() {
  let flagRun = false;

  useEffect( ()=>{
    if ( flagRun )
      return;
    ReactGA.initialize(config.ga4MeasurementID);
    ReactGA.send({ hitType:"pageview", page:'/', title: '' });
    console.log("GA Initialized");
    flagRun = true;
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        
        {/* <!-- TITLE --> */}
        <div className='header'>
           AI DOCENT
        </div>
        {/* <!-- 텍스트 --> */}
        <div>
          인공지능에게 물어보세요 
        </div>
        <div className="center-box"> 
          <DocentMain />
        </div>
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;

export let audioContext;
export let audioSource;

export function initAudio()
{
  audioContext = new AudioContext();
  audioSource = audioContext.createBufferSource();
}
export function renewAudio()
{
  audioContext.close();
  initAudio();
}
const config={
  maxTimeRecord: 5,   // 녹음시 최대 5초까지

  env:'production',
  apiServerURL: 'https://aidocent-api.netstream.co.kr',
  ga4MeasurementID: 'G-LLJL16X33W',

  // ga4MeasurementID: 'G-WB3LS4NEBE',   // event-anc

  // env:'development',
  // apiServerURL: 'http://localhost:4000',
  // ga4MeasurementID: 'G-',

  conversationID : null,
  apiKey: 'e4-sample',
  audioContent: '',

}

export default config;